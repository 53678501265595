
import { reactive, ref, toRefs, onMounted } from "vue";
import type { FormInstance, FormRules, FormProps } from "element-plus";
import { ElMessage } from "element-plus";
import router from "@/router";
import api from "@/request/api";
import i18n from "@/lang/index";
import enLocale from "@/lang/en";
import zhLocale from "@/lang/zh";
import watermark from "../../utils/watermark";
import { useI18n } from "vue-i18n";
import { useThrottle } from "@/utils/useThrottle";

export default {
  setup() {
    const data = reactive({
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入用户", trigger: "blur" }],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    });

    const labelPosition = ref<FormProps["labelPosition"]>("top");
    const ruleFormRef = ref<FormInstance>();
    const submitForm = useThrottle((formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          api.post("user/login", data.ruleForm).then((res: any) => {
            if (res && res.code == 20000) {
              ElMessage({
                message: "登录成功",
                type: "success",
              });
              let username = data.ruleForm.username;
              let role: any = res.data.role;
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("username", username);
              sessionStorage.setItem("nickname", res.data.nickname);
              sessionStorage.setItem("role", role);
              sessionStorage.setItem("auth", res.data.auth);
              if (res.data.auth && res.data.auth !== "") {
                setTimeout(function () {
                  router.push("/home/index");
                }, 1500);
              } else {
                router.replace("/403");
              }
            } else {
            }
          });
        } else {
          return false;
        }
      });
    }, 1000);

    // 获取浏览器视口的分辨率
    const viewportWidth = window.innerWidth;
    let pc = ref("1");
    if (viewportWidth > 1024) {
      pc.value = "1";
      sessionStorage.setItem("pc", pc.value);
    } else {
      pc.value = "2";
      sessionStorage.setItem("pc", pc.value);
    }

    // 语言切换
    const { locale } = useI18n();
    let languageShow = ref(sessionStorage.getItem("langShow") || "2");
    const changeLg = () => {
      languageShow.value = "2";
      locale.value = "enLocale";
      sessionStorage.setItem("lang", locale.value);
      sessionStorage.setItem("langShow", languageShow.value);
    };
    const changeLgS = () => {
      languageShow.value = "1";
      locale.value = "zhLocale";
      sessionStorage.setItem("lang", locale.value);
      sessionStorage.setItem("langShow", languageShow.value);
    };
    return {
      ...toRefs(data),
      ruleFormRef,
      submitForm,
      labelPosition,
      languageShow,
      changeLg,
      changeLgS,
    };
  },
};
